.App {
  padding-top: 100px;
  height: 100%;
}
html,
body {
  height: 100%;
}
#root {
  height: 100%;
}
.form-fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.form-fields div {
  margin: 10px;
}

.form {
  border-radius: 7px;
  box-shadow: 0 0 2px grey;
}

.form h2 {
  text-align: center;
}

.form button {
  width: 100px;
}

.users,
.businesses {
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.user,
.business {
  width: 400px;
  margin: 10px;
  background-color: lightgrey;
  border-radius: 7px;
}

.business {
  width: 100%;
}

.user div,
.business div {
  border-radius: 7px;
}
.user th,
.user label,
.business th,
.business label {
  width: 100px;
  padding: 5px;
  font-weight: 400;
}

.business td:first-child,
.business label {
  width: 15%;
  padding: 5px;
  min-width: 100px;
}
.user button,
.business button {
  margin-top: 10px;
  margin-left: 10px;
}
.abt-text {
  width: 100%;
}
.abt-text textarea {
  width: 100%;
  height: 150px;
}

.form-fields input,
.form-fields select {
  width: 200px;
}

.images {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.images div {
  margin: 10px;
}
.tier-2 input,
.tier-2 select {
  width: 200px;
  padding: 4px;
  margin: 10px 20px;
  margin-left: 0;
}

.tier-2 textarea {
  width: 100%;
  height: 100px;
  padding: 4px;
}

.tier-2 fieldset {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.tier-2 .t-area {
  width: 100%;
}

.group {
  border: solid 1px grey;
  padding: 10px;
  border-radius: 5px;
  margin: 20px 0;
}

.table-multi {
  display: flex;
  flex-wrap: wrap;
}

.table-multi div {
  width: 250px;
}
.admin-items {
  text-align: center;
}
.admin-items .itms {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.admin-items .item {
  width: 250px;
  border: solid 1px grey;
  margin: 5px 10px;
  margin-left: 0;
  padding: 7px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.admin-items .item img {
  width: 100%;
}

.admin-items .add-item {
  width: 250px;
  padding: 10px;
}
.admin-items .add-item input,
.admin-items .add-item textarea {
  width: 100%;
}

.login {
  width: 90%;
  max-width: 500px;
  text-align: center;
}

.login input {
  width: 100%;
}
